import React, { useContext } from "react";
import styles from "./PAndPSection.module.css";
import PartnersList from "../../../utils/partnerslist/PartnersListUtil";
import "./PAndPSection.module.css";
import { TextResourceContext } from "../../../contexts/TextResourceContext";
import AcknowledgementsUtil from "../../../utils/acknowledgements/AcknowledgementsUtil";
import { Slide } from "react-awesome-reveal";

const PAndPSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.PAndPSection;
  return (
    <section id="p-and-p" className={`${styles["p-and-p"]}`}>
      <div className="container">
        <div className={`${styles["section-header"]} pb-sm-3 pb-lg-5`}>
          <h3 className={styles["section-title"]}>{texts.projectsHeader}</h3>
          <span className={styles["section-divider"]}></span>
        </div>
        <Slide direction="up" triggerOnce>
          <PartnersList />
          <AcknowledgementsUtil />
        </Slide>
      </div>
    </section>
  );
};

export default PAndPSection;
