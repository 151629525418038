import React from "react";
import { BrowserRouter } from "react-router-dom";
import { TextResourceProvider } from "./contexts/TextResourceContext";
import Navigation from "./navigation";

const App = () => {
  return (
    <BrowserRouter>
      <TextResourceProvider>
        <Navigation />
      </TextResourceProvider>
    </BrowserRouter>
  );
};

export default App;
