import React, { useContext } from "react";
import styles from "./ApplicationsSection.module.css";
import features from "../../../img/features_photo.jpg";
import { Slide } from "react-awesome-reveal";
import { TextResourceContext } from "../../../contexts/TextResourceContext";

const ApplicationsSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.ApplicationsSection;

  const talentsDevelopmentItems = [
    texts.talentsDevelopmentItem1,
    texts.talentsDevelopmentItem2,
    texts.talentsDevelopmentItem3,
    texts.talentsDevelopmentItem4,
    texts.talentsDevelopmentItem5,
  ];

  return (
    <section className={`${styles["advanced-features"]} ${styles.features}`}>
      <div className={styles["features-row"]}>
        <div className="container">
          <div className="section-header pb-sm-3 pb-lg-5">
            <h3 className="section-title">{texts.talentsDevelopmentTitle}</h3>
            <span className="section-divider"></span>
          </div>
          <div className="row">
            <div className="col-lg-6 order-last order-lg-first text-lg-right  ">
              <Slide direction="left" triggerOnce>
                <p>{texts.talentsDevelopmentContent}:</p>
                <ul>
                  {talentsDevelopmentItems.map((item, index) => (
                    <li key={index}> {item}</li>
                  ))}
                </ul>
              </Slide>
            </div>
            <div className={`col-lg-6 ${styles["advanced-img"]}`}>
              <Slide direction="right" triggerOnce>
                <img
                  src={features}
                  className="mx-auto d-block"
                  alt="Smartphone used for content capturing"
                />
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplicationsSection;
