import React, { useEffect, useContext } from "react";
import Intro from "./intro/IntroSection";
import AboutUs from "./aboutus/AboutUsSection";
import Applications from "./applications/ApplicationsSection";
import PAndP from "./pandp/PAndPSection";
import Team from "./team/TeamSection";
import Careers from "./career/CareersSection";
import Contacts from "./contacts/ContactsSection";
import TechStack from './techstack/TechStackSection';
import Portfolio from './portfolio/PortfolioSection';
import { TextResourceContext } from "../../contexts/TextResourceContext";
import Loader from "../../components/loader/LoaderComponent";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const { setLanguage, isLoading, setLoader } = useContext(TextResourceContext);
  const { pathname } = useLocation();

  useEffect(() => {
    const firstPath = pathname.split("/")[1];
    setLoader(true);
    setLanguage(firstPath).then(() => setLoader(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!isLoading) {
    return (
      <div>
        <Intro />
        <AboutUs />
        <Applications />
        <Portfolio />
        <PAndP />
        <TechStack />
        <Team />
        <Careers />
        <Contacts />
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default HomePage;
