import React from "react";
import styles from "./AcknowledgementsUtil.module.css";
import Ecommerce from "../../img/badges/ecommerce_development_2023.png";
import ReactNative from "../../img/badges/react_native_development_2023.png";
import NvidiaBadge from "../../img/badges/nvidia_badge.png";
import Augmentation from "../../img/badges/clutch_top_augmentation.png";

const AcknowledgementsUtil = () => {
  const imageAttributes = [
    {
      id: 1,
      src: Augmentation,
      alt: "Augmentation",
    },
    {
      id: 2,
      src: Ecommerce,
      alt: "Ecommerce",
    },
    {
      id: 4,
      src: ReactNative,
      alt: "ReactNative",
    },
  ];
  return (
    <div className={styles.badges}>
      <div className="row justify-content-center ">
        <div
          className={` ${styles.badgeWrapper} col-12 d-flex justify-content-center align-items-center px-0`}
          id="popover"
          key={1}
        >
          <img className="img-fluid " src={NvidiaBadge} alt={"NvidiaBadge"} />
        </div>
        {imageAttributes.map(({ id, src, alt }) => {
          return (
            <div
              className={` ${styles.badgeWrapper} col-6 col-md-1 d-flex justify-content-center align-items-center px-0`}
              id="popover"
              key={id}
            >
              <img className="img-fluid " src={src} alt={alt} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AcknowledgementsUtil;
