import * as actions from "./actions";

const AppReducer = (state, action) => {
  switch (action.type) {
    case actions.SET_TXT_RESOURCES:
      return {
        ...state,
        textResources: { ...state.textResources, ...action.payload }
      };
    case actions.SET_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case actions.SET_LANGUAGE:
      return {
        ...state,
        currentLang: action.payload
      };
    default:
      return state;
  }
};

export default AppReducer;
