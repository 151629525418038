import { useContext, useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from "react-ga4";
import { TextResourceContext } from "../../contexts/TextResourceContext";
import LinkedInTag from "react-linkedin-insight";

const CookieComponent = () => {
  const { textResources, isLoading } = useContext(TextResourceContext);
  const texts = textResources.CookieComponent;

  const initGoogleAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  };

  const initLinkedInTag = () => {
    LinkedInTag.init(process.env.REACT_APP_LINKEDIN_PARTNER_ID, "dc", false);
  };

  const onCoockieAccept = () => {
    initGoogleAnalytics();
    initLinkedInTag();
  };
  useEffect(() => {
    if (Cookies.get().isCookiesAllowed) {
      onCoockieAccept();
    }
  }, []);

  return (
    !isLoading && (
      <CookieConsent
        disableButtonStyles
        style={{ background: "#d48639", padding: "20px" }}
        buttonClasses="cc-btn mr-3"
        buttonText={texts.cookieButton}
        cookieName="isCookiesAllowed"
        onAccept={onCoockieAccept}
      >
        {texts.cookieContent}{" "}
      </CookieConsent>
    )
  );
};

export default CookieComponent;
