import React, { useContext } from "react";
import applications from "../../../img/applications_photo.jpg";
import styles from "./CareersSection.module.css";
import { Slide } from "react-awesome-reveal";
import { TextResourceContext } from "../../../contexts/TextResourceContext";

const CareersSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.CareersSection;

  return (
    <section id="careers" className={styles.careers}>
      <div className={styles["features-row"]}>
          <div className="section-header pb-sm-4 pb-lg-4">
                <h3 className="section-title">{texts.careersTitle}</h3>
                <span className="section-divider"></span>
          </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Slide direction="left" triggerOnce>
                <img
                  className={styles["advanced-feature-img-left"]}
                  src={applications}
                  alt="Business profesionals' communication"
                />
              </Slide>
              <Slide direction="right" triggerOnce>
                
                <div className={styles.careers_text}
                  dangerouslySetInnerHTML={{ __html: texts.careersContent }}
                ></div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersSection;
