import React, { useContext, useEffect } from "react";
import { TextResourceContext } from "../../contexts/TextResourceContext";
import Loader from "../../components/loader/LoaderComponent";
import { useLocation } from "react-router-dom";

const TermsPage = () => {
  const { setLanguage, isLoading, setLoader } = useContext(TextResourceContext);
  const { pathname } = useLocation();
  const firstPath = pathname.split("/")[1];

  useEffect(() => {
    setLoader(true);
    setLanguage(firstPath.length === 2 ? firstPath : "En").then(() =>
      setLoader(false)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!isLoading) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `<br/><br/><main id="main">
  <div class="container my-5">
<h1>Terms and Conditions (&quot;Terms&quot;)</h1>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">Last updated: 2020-10-01</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><a name="_GoBack"></a>Please read these
Terms and Conditions (&quot;Terms&quot;, &quot;Terms and Conditions&quot;)
carefully before using the <b>https://www.confistech.com,
https://www.anymoment.app</b> websites (the <b>&ldquo;Site&rdquo;</b>)
and the <b>AnyMoment</b> mobile application (the <b>&ldquo;application&rdquo;</b>)
or collectively referred to as the &quot;Service&quot; operated by
UAB ConfiStecH (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">Your access to and use of the Service
is conditioned on your acceptance of and compliance with these Terms.
These Terms apply to all visitors, users and others who access or use
the Service.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>By accessing or using the Service
you agree to be bound by these Terms. If you disagree with any part
of the terms then you may not access the Service.</b></p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Supplemental terms
and conditions or documents that may be posted on the Service from
time to time are hereby expressly incorporated herein by reference.
We reserve the right, in our sole discretion, to make changes or
modifications to these Terms and Conditions at any time and for any
reason. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We will alert you
about any changes by updating the &ldquo;Last updated&rdquo; date of
these Terms and Conditions, and you waive any right to receive
specific notice of each such change. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">It is your
responsibility to periodically review these Terms and Conditions to
stay informed of updates. You will be subject to, and will be deemed
to have been made aware of and to have accepted, the changes in any
revised Terms and Conditions by your continued use of the Service
after the date such revised Terms and Conditions are posted. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">The information
provided on the Service is not intended for distribution to or use by
any person or entity in any jurisdiction or country where such
distribution or use would be contrary to law or regulation or which
would subject us to any registration requirement within such
jurisdiction or country. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Accordingly, those
persons who choose to access the Service from other locations do so
on their own initiative and are solely responsible for compliance
with local laws, if and to the extent local laws are applicable.&nbsp;</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">All users who are minors in the
jurisdiction in which they reside (generally under the age of 18)
must have the permission of, and be directly supervised by, their
parent or guardian to use the Service. If you are a minor, you must
have your parent or guardian read and agree to these Terms and
Conditions prior to you using the Service.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>INTELLECTUAL PROPERTY RIGHTS</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Unless otherwise
indicated, the Service is our proprietary property and all source
code, databases, functionality, software, website designs, audio,
video, text, photographs, and graphics on the Service (collectively,
the &ldquo;Content&rdquo;) and the trademarks, service marks, and
logos contained therein (the &ldquo;Marks&rdquo;) are owned or
controlled by us or licensed to us, and are protected by copyright
and trademark laws and various other intellectual property rights and
unfair competition laws of the United States, foreign jurisdictions,
and international conventions. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">The Content and the
Marks are provided on the Service &ldquo;AS IS&rdquo; for your
information and personal use only. Except as expressly provided in
these Terms and Conditions, no part of the Service and no Content or
Marks may be copied, reproduced, aggregated, republished, uploaded,
posted, publicly displayed, encoded, translated, transmitted,
distributed, sold, licensed, or otherwise exploited for any
commercial purpose whatsoever, without our express prior written
permission.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Provided that you
are eligible to use the Service, you are granted a limited license to
access and use the Service and to download or print a copy of any
portion of the Content to which you have properly gained access
solely for your personal, non-commercial use. We reserve all rights
not expressly granted to you in and to the Service, the Content and
the Marks.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>USER REPRESENTATIONS</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">By using the Service, you represent and
warrant that:</p>
<p style="margin-left: 0.25in; margin-bottom: 0in"><br/>

</p>
<ol>
<li><p style="margin-bottom: 0in; line-height: 150%">You have the
legal capacity and you agree to comply with these Terms and
Conditions;</p>
<li><p style="margin-bottom: 0in; line-height: 150%">Not a minor in
the jurisdiction in which you reside (or if a minor, you have
received parental permission to use the Service);</p>
<li><p style="margin-bottom: 0in; line-height: 150%">You will not
use the Service for any illegal or unauthorized purpose; 
</p>
<li><p style="margin-bottom: 0in; line-height: 150%">Your use of the
Service will not violate any applicable law or regulation.</p>
</ol>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>PROHIBITED ACTIVITIES</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">The Service may not be used in
connection with any commercial endeavors except those that are
specifically endorsed or approved by us. 
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">As a user of the Service, you agree not
to:</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">1)
Systematically retrieve data or other content from the Service to
create or compile, directly or indirectly, a collection, compilation,
database, or directory without written permission from us.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">2)
Make any unauthorized use of the Service, including collecting
usernames and/or email addresses of users by electronic or other
means for the purpose of sending unsolicited email, or creating user
accounts by automated means or under false pretenses.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">3)
Use the Service to advertise or offer to sell goods and services.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">4)
Circumvent, disable, or otherwise interfere with security-related
features of the Service, including features that prevent or restrict
the use or copying of any Content or enforce limitations on the use
of the Service and/or the Content contained therein.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">5)
Trick, defraud, or mislead us and other users, especially in any
attempt to learn sensitive account information such as user
passwords;</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">6)
Make improper use of our support services or submit false reports of
abuse or misconduct.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">7)
Engage in any automated use of the system, such as using scripts to
send comments or messages, or using any data mining, robots, or
similar data gathering and extraction tools.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">8)
Interfere with, disrupt, or create an undue burden on the Service or
the networks or services connected to the Service.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">9)
Attempt to impersonate another user or person or use the username of
another user.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">10)
Sell or otherwise transfer your profile.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">11)
Use any information obtained from the Service in order to harass,
abuse, or harm another person.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">12)
Use the Service as part of any effort to compete with us or otherwise
use the Service and/or the Content for any revenue-generating
endeavor or commercial enterprise.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">13)
Decipher, decompile, disassemble, or reverse engineer any of the
software comprising or in any way making up a part of the Service.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">14)
Attempt to bypass any measures of the Service designed to prevent or
restrict access to the Service, or any portion of the Service.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">15)
Harass, annoy, intimidate, or threaten any of our employees or agents
engaged in providing any portion of the Service to you.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">16)
Delete the copyright or other proprietary rights notice from any
Content.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">17)
Copy or adapt the Service&rsquo;s software, including but not limited
to Flash, PHP, HTML, JavaScript, or other code.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">18)
Upload or transmit (or attempt to upload or to transmit) viruses,
Trojan horses, or other material, including excessive use of capital
letters and spamming (continuous posting of repetitive text), that
interferes with any party&rsquo;s uninterrupted use and enjoyment of
the Service or modifies, impairs, disrupts, alters, or interferes
with the use, features, functions, operation, or maintenance of the
Service.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">19)
Upload or transmit (or attempt to upload or to transmit) any material
that acts as a passive or active information collection or
transmission mechanism, including without limitation, clear graphics
interchange formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web bugs,
cookies, or other similar devices (sometimes referred to as &ldquo;spyware&rdquo;
or &ldquo;passive collection mechanisms&rdquo; or &ldquo;pcms&rdquo;).</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">20)
Except as may be the result of standard search engine or Internet
browser usage, use, launch, develop, or distribute any automated
system, including without limitation, any spider, robot, cheat
utility, scraper, or offline reader that accesses the Service, or
using or launching any unauthorized script or other software.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">21)
Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
Service.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">22)
Use the Service in a manner inconsistent with any applicable laws or
regulations.</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">23)
Make any modification, adaptation, improvement, enhancement,
translation, or derivative work from the Service;</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">24)
Make the Service available over a network or other environment
permitting access or use by multiple devices or users at the same
time; 
</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">25)
Use the Service for creating a product, service, or software that is,
directly or indirectly, competitive with or in any way a substitute
for the Service;</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%">26)
Use any proprietary information or any of our interfaces or our other
intellectual property in the design, development, manufacture,
licensing, or distribution of any applications, accessories, or
devices for use with the Service.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>USER GENERATED CONTENT</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">Some of the features of the Service may
allow to create, submit, display, transmit, distribute content and
materials to us or on the Service, including but not limited to text,
writings, video, audio, photographs, graphics, comments, suggestions,
or personal information or other material (collectively,
&quot;Contributions&quot;). As such, any Contributions you transmit
may be treated as non-confidential and non-proprietary. When you
create or make available any Contributions, you thereby represent and
warrant that:</p>
<p style="margin-bottom: 0in"><br/>

</p>
<ol>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
The creation, distribution, transmission, public display, or
performance, and the accessing, downloading, or copying of your
Contributions do not and will not infringe the proprietary rights,
including but not limited to the copyright, patent, trademark, trade
secret, or moral rights of any third party.</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
You are the creator and owner of or have the necessary licenses,
rights, consents, releases, and permissions to use and to authorize
us, the Service, and other users of the Service to use your
Contributions in any manner contemplated by the Service and these
Terms and Conditions.</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions are not obscene, lewd, lascivious, filthy,
violent, harassing, libelous, slanderous, or otherwise objectionable
(as determined by us).</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions do not ridicule, mock, disparage, intimidate, or
abuse anyone.</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions do not advocate the violent overthrow of any
government or incite, encourage, or threaten physical harm against
another.</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions do not violate any applicable law, regulation, or
rule.</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions do not violate the privacy or publicity rights of
any third party.</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions do not contain any material that solicits
personal information from anyone under the age of 18 or exploits
people under the age of 18 in a sexual or violent manner.</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions do not violate any federal or state law
concerning child pornography, or otherwise intended to protect the
health or well-being of minors;</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions do not include any offensive comments that are
connected to race, national origin, gender, sexual preference, or
physical handicap.</p>
<li><p style="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%; orphans: 0; widows: 0">
Your Contributions do not otherwise violate, or link to material
that violates, any provision of these Terms and Conditions, or any
applicable law or regulation.</p>
</ol>
<p style="margin-left: 0.3in; text-indent: -0.3in; margin-bottom: 0in; line-height: 150%">
<br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Any use of the
Service in violation of the foregoing violates these Terms and
Conditions and may result in, among other things, termination or
suspension of your rights to use the Service. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>CONTRIBUTION
LICENSE</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">By posting your
Contributions to any part of the Service [or making Contributions
accessible to the Service by linking your account from the Service to
any of your social networking accounts], you automatically grant, and
you represent and warrant that you have the right to grant, to us an
unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
transferable, royalty-free, fully-paid, worldwide right, and license
to host, use, copy, reproduce, disclose, publish, broadcast, retitle,
archive, store, cache, reformat, translate, transmit, excerpt (in
whole or in part), and distribute such Contributions for any purpose,
commercial, or otherwise to the extent necessary to perform intended
features of the Service or respond to legal enquiries, and to prepare
derivative works of, or incorporate into other works, such
Contributions, and grant and authorize sublicenses of the foregoing.
The use and distribution may occur in any media formats and through
any media channels.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We do not assert any
ownership over your Contributions. You retain full ownership of all
of your Contributions and any intellectual property rights or other
proprietary rights associated with your Contributions. We are not
liable for any statements or representations in your Contributions
provided by you in any area on the Service. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">You are solely
responsible for your Contributions to the Service and you expressly
agree to exonerate us from any and all responsibility and to refrain
from any legal action against us regarding your Contributions.  
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We have the right,
in our sole and absolute discretion, (1) to edit, redact, or
otherwise change any Contributions; (2) to re-categorize any
Contributions to place them in more appropriate locations on the
Service; and (3) to pre-screen or delete any Contributions at any
time and for any reason, without notice. We have no obligation to
monitor your Contributions. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>MOBILE
APPLICATION LICENSE</b></p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">If you access the
Service via a mobile application (AnyMoment), then we grant you a
revocable, non-exclusive, non-transferable, limited right to install
and use the mobile application on wireless electronic devices owned
or controlled by you, and to access and use the mobile application on
such devices strictly in accordance with the terms and conditions of
this mobile application license contained in these Terms and
Conditions. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">The following terms
apply when you use a mobile application obtained from either the
Apple Store or Google Play (each an &ldquo;App Distributor&rdquo;) to
access the Service: 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<ol>
<li><p style="margin-bottom: 0in; line-height: 150%">The license
granted to you for our mobile application is limited to a
non-transferable license to use the application on a device that
utilizes the Apple iOS or Android operating systems, as applicable,
and in accordance with the usage rules set forth in the applicable
App Distributor&rsquo;s terms of service.</p>
</ol>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>SOCIAL MEDIA</b></p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">As part of the
functionality of the Service, you may link your account with online
accounts you have with third-party service providers (each such
account, a &ldquo;Third-Party Account&rdquo;) by allowing us to
access your Third-Party Account, as is permitted under the applicable
terms and conditions that govern your use of each Third-Party
Account. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">You represent and
warrant that you are entitled to disclose your Third-Party Account
login information to us and/or grant us access to your Third-Party
Account, without breach by you of any of the terms and conditions
that govern your use of the applicable Third-Party Account, and
without obligating us to pay any fees or making us subject to any
usage limitations imposed by the third-party service provider of the
Third-Party Account. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">By granting us
access to any Third-Party Accounts, you understand that we may submit
to and receive from your Third-Party Account additional information
to the extent you are notified when you link your account with the
Third-Party Account. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Depending on the
Third-Party Accounts you choose and subject to the privacy settings
that you have set in such Third-Party Accounts, personally
identifiable information that you post to your Third-Party Accounts
may be available on and through your account on the Service. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Please note that if
a Third-Party Account or associated service becomes unavailable or
our access to such Third-Party Account is terminated by the
third-party service provider, then Social Network Content may no
longer be available on and through the Service.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">PLEASE NOTE THAT
YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. 
</p>
<p style="margin-bottom: 0in; line-height: 150%">We make no effort to
review any Social Network Content for any purpose, including but not
limited to, for accuracy, legality, or non-infringement, and we are
not responsible for any Social Network Content. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>SUBMISSIONS</b></p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">You acknowledge and
agree that any questions, comments, suggestions, ideas, feedback, or
other information regarding the Service (&quot;Submissions&quot;)
provided by you to us are non-confidential and shall become our sole
property. We shall own exclusive rights, including all intellectual
property rights, and shall be entitled to the unrestricted use and
dissemination of these Submissions for any lawful purpose, commercial
or otherwise, without acknowledgment or compensation to you. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">You hereby waive all
moral rights to any such Submissions, and you hereby warrant that any
such Submissions are original with you or that you have the right to
submit such Submissions. You agree there shall be no recourse against
us for any alleged or actual infringement or misappropriation of any
proprietary right in your Submissions.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>THIRD-PARTY
WEBSITES AND CONTENT</b></p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">The Service may
contain (or you may be sent via the Service) links to other websites
(&quot;Third-Party Websites&quot;) as well as articles, photographs,
text, graphics, pictures, designs, music, sound, video, information,
applications, software, and other content or items belonging to or
originating from third parties (&quot;Third-Party Content&quot;). 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Such Third-Party
Websites and Third-Party Content are not investigated, monitored, or
checked for accuracy, appropriateness, or completeness by us, and we
are not responsible for any Third-Party Websites accessed through the
Service or any Third-Party Content posted on, available through, or
installed from the Service, including the content, accuracy,
offensiveness, opinions, reliability, privacy practices, or other
policies of or contained in the Third-Party Websites or the
Third-Party Content. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Inclusion of,
linking to, or permitting the use or installation of any Third-Party
Websites or any Third-Party Content does not imply approval or
endorsement thereof by us. If you decide to leave the Service and
access the Third-Party Websites or to use or install any Third-Party
Content, you do so at your own risk, and you should be aware these
Terms and Conditions no longer govern. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">You should review
the applicable terms and policies, including privacy and data
gathering practices, of any website to which you navigate from the
Service or relating to any applications you use or install from the
Service. Any purchases you make through Third-Party Websites will be
through other websites and from other companies, and we take no
responsibility whatsoever in relation to such purchases which are
exclusively between you and the applicable third party. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">You agree and
acknowledge that we do not endorse the products or services offered
on Third-Party Websites and you shall hold us harmless from any harm
caused by your purchase of such products or services. Additionally,
you shall hold us harmless from any losses sustained by you or harm
caused to you relating to or resulting in any way from any
Third-Party Content or any contact with Third-Party Websites.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>SERVICE
MANAGEMENT</b></p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We reserve the
right, but not the obligation, to: 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">1) Monitor the
Service for violations of these Terms and Conditions; 
</p>
<p style="margin-bottom: 0in; line-height: 150%">2) Take appropriate
legal action against anyone who, in our sole discretion, violates the
law or these Terms and Conditions, including without limitation,
reporting such user to law enforcement authorities; 
</p>
<p style="margin-bottom: 0in; line-height: 150%">3) In our sole
discretion and without limitation, refuse, restrict access to, limit
the availability of, or disable (to the extent technologically
feasible) any of your Contributions or any portion thereof; 
</p>
<p style="margin-bottom: 0in; line-height: 150%">4) In our sole
discretion and without limitation, notice, or liability, to remove
from the Service or otherwise disable all files and content that are
excessive in size or are in any way burdensome to our systems; 
</p>
<p style="margin-bottom: 0in; line-height: 150%">5) Otherwise manage
the Service in a manner designed to protect our rights and property
and to facilitate the proper functioning of the Service.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>COPYRIGHT,
INTELLECTUAL PROPERTY RIGHTS INFRINGEMENTS</b></p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We respect the
intellectual property rights of others. If you believe that any
material available on or through the Service infringes upon any
copyright you own or control, please immediately notify us using the
contact information provided below (a &ldquo;Notification&rdquo;). A
copy of your Notification will be sent to the person who posted or
stored the material addressed in the Notification. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Please be advised
that pursuant to federal law you may be held liable for damages if
you make material misrepresentations in a Notification. Thus, if you
are not sure that material located on or linked to by the Service
infringes your copyright, you should consider first contacting an
attorney.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>TERM AND
TERMINATION</b></p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">These Terms and
Conditions shall remain in full force and effect while you use the
Service. Without limiting any other provision of these terms and
conditions, we reserve the right to, in our sole discretion and
without notice or liability, deny access to and use of the Service
(including blocking certain ip addresses), to any person for any
reason or for no reason, including without limitation for breach of
any representation, warranty, or covenant contained in these terms
and conditions or of any applicable law or regulation. We may
terminate your use or participation in the Service or delete [your
account and] any content or information that you posted at any time,
without warning, in our sole discretion. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">If we terminate or
suspend your account for any reason, you are prohibited from
registering and creating a new account under your name, a fake or
borrowed name, or the name of any third party, even if you may be
acting on behalf of the third party. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">In addition to
terminating or suspending your account, we reserve the right to take
appropriate legal action, including without limitation pursuing
civil, criminal, and injunctive redress.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">These Terms and
Conditions and any policies or operating rules posted by us on the
Service constitute the entire agreement and understanding between you
and us. Our failure to exercise or enforce any right or provision of
these Terms and Conditions shall not operate as a waiver of such
right or provision. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">These Terms and
Conditions operate to the fullest extent permissible by law. We may
assign any or all of our rights and obligations to others at any
time. We shall not be responsible or liable for any loss, damage,
delay, or failure to act caused by any cause beyond our reasonable
control. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">If any provision or
part of a provision of these Terms and Conditions is determined to be
unlawful, void, or unenforceable, that provision or part of the
provision is deemed severable from these Terms and Conditions and
does not affect the validity and enforceability of any remaining
provisions. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">You hereby waive any
and all defenses you may have based on the electronic form of these
Terms and Conditions and the lack of signing by the parties hereto to
execute these Terms and Conditions.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%"><b>MODIFICATIONS,
CORRECTIONS AND INTERRUPTIONS </b>
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We reserve the right
to change, modify, or remove the contents of the Service at any time
or for any reason at our sole discretion without notice. However, we
have no obligation to update any information on our Service. We also
reserve the right to modify or discontinue all or part of the Service
without notice at any time. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We will not be
liable to you or any third party for any modification, price change,
suspension, or discontinuance of the Service.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">There may be
information on the Service that contains typographical errors,
inaccuracies, or omissions that may relate to the Service, including
descriptions, pricing, availability, and various other information.
We reserve the right to correct any errors, inaccuracies, or
omissions and to change or update the information on the Service at
any time, without prior notice.</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We cannot guarantee
the Service will be available at all times. We may experience
hardware, software, or other problems or need to perform maintenance
related to the Service, resulting in interruptions, delays, or
errors. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We reserve the right
to change, revise, update, suspend, discontinue, or otherwise modify
the Service at any time or for any reason without notice to you. You
agree that we have no liability whatsoever for any loss, damage, or
inconvenience caused by your inability to access or use the Service
during any downtime or discontinuance of the Service. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">Nothing in these
Terms and Conditions will be construed to obligate us to maintain and
support the Service or to supply any corrections, updates, or
releases in connection therewith.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>INDEMNIFICATION</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">You agree to defend,
indemnify, and hold us harmless, including our subsidiaries,
affiliates, and all of our respective officers, agents, partners, and
employees, from and against any loss, damage, liability, claim, or
demand, including reasonable attorneys&rsquo; fees and expenses, made
by any third party due to or arising out of: (1) your Contributions;
(2) use of the Service; (3) breach of these Terms and Conditions; (4)
any breach of your representations and warranties set forth in these
Terms and Conditions; (5) your violation of the rights of a third
party, including but not limited to intellectual property rights; or
(6) any overt harmful act toward any other user of the Service with
whom you connected via the Service. 
</p>
<p style="margin-bottom: 0in; line-height: 150%"><br/>

</p>
<p style="margin-bottom: 0in; line-height: 150%">We will use
reasonable efforts to notify you of any such claim, action, or
proceeding which is subject to this indemnification upon becoming
aware of it.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>USER DATA</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">We will maintain certain data that you
transmit to the Service for the purpose of managing the Service, as
well as data relating to your use of the Service. Although we perform
regular routine backups of data, you are solely responsible for all
data that you transmit or that relates to any activity you have
undertaken using the Service. 
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">You agree that we shall have no
liability to you for any loss or corruption of any such data, and you
hereby waive any right of action against us arising from any such
loss or corruption of such data.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>ELECTRONIC COMMUNICATIONS,
TRANSACTIONS, AND SIGNATURES</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">Visiting the Service, sending us
emails, and completing online forms constitute electronic
communications. You consent to receive electronic communications, and
you agree that all agreements, notices, disclosures, and other
communications we provide to you electronically, via email and on the
Service, satisfy any legal requirement that such communication be in
writing. 
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">YOU HEREBY AGREE TO THE USE OF
ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO
ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
INITIATED OR COMPLETED BY US OR VIA THE SERVICE. 
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">You hereby waive any rights or
requirements under any statutes, regulations, rules, ordinances, or
other laws in any jurisdiction which require an original signature or
delivery or retention of non-electronic records, or to payments or
the granting of credits by any means other than electronic means.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><b>CONTACT US</b></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">In order to resolve a complaint
regarding the Service or to receive further information regarding use
of the Service or these terms please contact us at:</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">UAB ConfiStecH</p>
<p style="margin-bottom: 0in"><a href="mailto:help@confistech.com">help@confistech.com</a>
</p>
  </div>
</main><!-- End #main -->`
        }}
      ></div>
    );
  } else {
    return <Loader />;
  }
};

export default TermsPage;
