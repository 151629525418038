import React, { useContext } from "react";
import styles from "./AboutUsSection.module.css";
import about from "../../../img/about_photo.jpg";
import { Slide } from "react-awesome-reveal";
import { TextResourceContext } from "../../../contexts/TextResourceContext";

const AboutUsSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.talentsSolutionsSection;

  return (
    <section id="talents" className={`${styles.about} ${styles["section-bg"]}`}>
      <div className={styles["features-row"]}>
        <div id={styles.outer} className={"container"}>
          <div className="section-header pb-sm-3 pb-lg-5">
            <h3 className="section-title">{texts.talentsSolutionsTitle}</h3>
            <span className="section-divider"></span>
          </div>
          <div className="row">
            <div className={`col-lg-6 h-100 ${styles["about-img"]}`}>
              <Slide direction="left" triggerOnce>
                <img
                  className="img-fluid"
                  src={about}
                  alt="Smartphone in use by a user"
                />
              </Slide>
            </div>
            <div
              className={`col-lg-6 ${styles.content} text-md-left align-self-start`}
            >
              <Slide direction="right" triggerOnce>
                <div className={`${styles.contentinnercontainer}`}>
                  <h4 className={`text-md-justify ${styles.display}`}>
                    <span>{texts.talentsSolutionsHeader1} - </span>
                    {texts.talentsSolutionsSubheader1}
                  </h4>
                  <h4 className={`text-md-justify ${styles.display}`}>
                    <span>{texts.talentsSolutionsHeader2} - </span>
                    {texts.talentsSolutionsSubheader2}
                  </h4>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
