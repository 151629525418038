import React, { useContext, useState } from "react";
import logo from "../../../img/logo-dark.png";
import styles from "./ContactsSection.module.css";
import { Slide } from "react-awesome-reveal";
import { TextResourceContext } from "../../../contexts/TextResourceContext";
import { instance } from "../../../clients/baseClient";

const ContactsSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.ContactsSection;
  const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

  const initialFormState = {
    name: "",
    email: "",
    message: "",
  };

  const [responseMessage, setResponseMessage] = useState({
    message: "",
    isSuccess: false,
  });

  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    nameError: "",
    emailError: "",
    messageError: "",
  });

  const formValuesHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));

    let key = `${name}Error`;
    setFormErrors((prevState) => ({ ...prevState, [key]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form.name || !form.email || !form.message) {
      Object.entries(form).forEach(([key, _value]) => {
        if (!form[key].trim()) {
          setFormErrors((prevState) => ({
            ...prevState,
            [`${key}Error`]: texts.contactsEmptyField,
          }));
        }
      });
    } else if (!regex.test(form.email)) {
      setFormErrors((prevState) => ({
        ...prevState,
        emailError: texts.contactsBadEmail,
      }));
    } else {
      try {
        await instance.post(`api/Contact`, form);
        setResponseMessage((prevState) => ({
          ...prevState,
          message: texts.contactsSuccessMessage,
          isSuccess: true,
        }));
        setForm(initialFormState);
      } catch (error) {
        setResponseMessage((prevState) => ({
          ...prevState,
          message: texts.contactsFailureMessage,
          isSuccess: false,
        }));
      }
    }
  };

  return (
    <section id="contact" className={styles["contact"]}>
      <div className="section-header pb-sm-3 pb-lg-5">
        <h3 className="section-title">{texts.contactUsTitle}</h3>
        <span className="section-divider"></span>
      </div>
      <div className="container">
        <div className="row d-flex flex-md-row justify-content-around">
          <Slide
            direction="up"
            triggerOnce
            className="row d-flex justify-content-around col-md-4"
          >
            <div className={`${styles.logoMedia} col-md-12`}>
              <img
                src={logo}
                alt="ConfiStecH"
                height="154"
                className="mx-auto d-block img-fluid"
              />
              <Slide
                direction="up"
                triggerOnce
                className="row d-flex flex-md-row justify-content-around"
              >
                <div className={styles.info}>
                  <div>
                    <i className="ion-ios-telephone-outline"></i>
                    <p>+370 643 35365</p>
                  </div>
                  <div>
                    <i className="ion-ios-email-outline"></i>
                    <p>enterprise@confistech.com</p>
                  </div>

                  <div>
                    <i className="ion-ios-location-outline"></i>

                    <p>Taikos pr. 54, LT-51305 Kaunas</p>
                  </div>
                  <div>
                    <i className="ion-ios-paper-outline"></i>
                    <p>{texts.contactsCompanyRegCode}: 305426932</p>
                    <p>{texts.contactsCompanyVAT}: LT100014431913</p>
                  </div>
                </div>
              </Slide>
            </div>
          </Slide>

          <Slide
            direction="up"
            triggerOnce
            className="row d-flex align-items-center col-md-4 p-0"
          >
            <div className=" col-12 p-0 h-100 d-flex flex-column justify-content-center">
              <div
                className={`d-flex w-50 justify-content-center align-items-start h-100 ${styles.socialLinks}`}
              >
                <a
                  href={"https://www.linkedin.com/company/confistech/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="ion-social-linkedin-outline"></i>
                </a>
                <a
                  href={
                    "https://www.facebook.com/profile.php?id=100093952777376"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="ion-social-facebook"></i>
                </a>
              </div>
              <div className={styles["form"]}>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    {formErrors.nameError ? (
                      <span className={styles["validation"]}>
                        {formErrors.nameError}
                      </span>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      onChange={formValuesHandler}
                      value={form.name}
                      className={`form-control ${
                        formErrors.nameError ? styles["validation-border"] : ""
                      }`}
                      name="name"
                      id="name"
                      placeholder={texts.contactsNamePlaceholder}
                    />
                  </div>

                  <div className="form-group">
                    {formErrors.emailError ? (
                      <span className={styles["validation"]}>
                        {formErrors.emailError}
                      </span>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      onChange={formValuesHandler}
                      value={form.email}
                      className={`form-control ${
                        formErrors.emailError ? styles["validation-border"] : ""
                      }`}
                      name="email"
                      id="email"
                      placeholder={texts.contactsEmailPlaceholder}
                      noValidate
                    />
                  </div>

                  <div className="form-group">
                    {formErrors.messageError ? (
                      <span className={styles["validation"]}>
                        {formErrors.messageError}
                      </span>
                    ) : (
                      ""
                    )}
                    <textarea
                      className={`form-control ${
                        formErrors.messageError
                          ? styles["validation-border"]
                          : ""
                      }`}
                      onChange={formValuesHandler}
                      value={form.message}
                      name="message"
                      id="email"
                      rows="5"
                      placeholder={texts.contactsMessagePlaceholder}
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button type="submit" title="Send Message">
                      {texts.contactsSendButton}
                    </button>
                    <div
                      className={`${styles.hideMessage} ${
                        responseMessage.message
                          ? `${styles.showMessage} ${
                              responseMessage.isSuccess
                                ? styles.success_message
                                : styles.error_message
                            }`
                          : ``
                      }`}
                    >
                      <span
                        className={`${styles.hideMessage} ${
                          responseMessage.message
                            ? `${styles.showMessage} ${
                                responseMessage.isSuccess
                                  ? styles.success_message
                                  : styles.error_message
                              }`
                            : ``
                        }`}
                      >
                        {responseMessage.message}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </section>
  );
};

export default ContactsSection;
