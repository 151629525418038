import React, { useEffect, useState, useContext } from "react";
import logo from "../../img/logo-light.png";
import styles from "./HeaderUtil.module.css";
import { sections } from "../../components/sections";
import { Link, useLocation } from "react-router-dom";
import { TextResourceContext } from "../../contexts/TextResourceContext";

const HeaderUtil = ({ isAnimationDisabled }) => {
  const [isTop, setIsTop] = useState(true);
  const [isLargeView, setIsLargeView] = useState(window.innerWidth > 1201);
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const { pathname } = useLocation();
  const firstPath = pathname.split("/")[1];
  const { textResources, isLoading } = useContext(TextResourceContext);

  useEffect(() => {
    const headerResizing = () => {
      const isTopPosition = window.scrollY < 100;
      setIsTop(isTopPosition);
    };
    const headerClassToggle = () => {
      const islargeview = window.innerWidth > 1201;
      setIsLargeView(islargeview);
    };

    document.addEventListener("scroll", headerResizing);
    window.addEventListener("resize", headerClassToggle);

    return () => {
      document.removeEventListener("scroll", headerResizing);
      window.removeEventListener("resize", headerClassToggle);
    };
  }, []);
  
  return (
    !isLoading && (
      <header
        id={`${styles.header}`}
        className={
          isAnimationDisabled
            ? styles.scrolledDown
            : isTop
            ? styles.scrolledUp
            : styles.scrolledDown
        }
      >
        <div className={isLargeView ? "container" : "container-fluid"}>
          <div
            id={styles.logo}
            className={`
            float-left
            ml-xl-4
            ${
              isAnimationDisabled
                ? styles.scaleDown
                : isTop
                ? styles.scaleUp
                : styles.scaleDown
            }
          `}
          >
            <a href="/#intro">
              <img src={logo} alt="ConfiStecH" />
            </a>
          </div>
          <nav id={styles["nav-menu-container"]}>
            <ul className={styles["nav-menu"]}>
              {sections.map(({ link, title }) => (
                <li key={link}>
                  <Link to={link}>{textResources.sections[title]}</Link>
                </li>
              ))}
              <li
                className={`nav-item dropdown ${isDropdownShown ? "show" : ""}`}
              >
                <button
                  onClick={() => setIsDropdownShown(!isDropdownShown)}
                  className="nav-link btn-link btn text-white dropdown-toggle"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {firstPath.length === 2 ? firstPath.toUpperCase() : "EN"}
                </button>
                <div
                  className={`dropdown-menu dropdown-menu-right  ${
                    isDropdownShown ? "show" : ""
                  }`}
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a
                    className={`dropdown-item ${styles["dropdown-item"]} rounded `}
                    href="/en/"
                  >
                    EN
                  </a>
                  <a
                    className={`dropdown-item ${styles["dropdown-item"]} rounded `}
                    href="/lt/"
                  >
                    LT
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
  );
};

export default HeaderUtil;
