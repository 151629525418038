import React from "react";
import styles from "./NotFoundPage.module.css";

const NotFoundPage = () => {
  return (
    <div className={styles.notFound}>
      <h1>404</h1>
      <h2>Sorry, the page you are looking for could not be found.</h2>
      <a href="/">Return Home</a>
    </div>
  );
};

export default NotFoundPage;
