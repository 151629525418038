import React, { useState, useContext } from "react";
import styles from "./MobileMenuUtil.module.css";
import { sections } from "../../components/sections";
import { Link } from "react-router-dom";
import { TextResourceContext } from "../../contexts/TextResourceContext";
import { useLocation } from "react-router-dom";

const MobileMenuUtil = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.MobileMenuUtil;
  const { pathname } = useLocation();
  const firstPath = pathname.split("/")[1];

  const toggleBodyClass = () => {
    if (!isOpen) {
      document.body.classList.add("mobile-nav-active");
    } else {
      document.body.classList.remove("mobile-nav-active");
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
          toggleBodyClass();
        }}
        type="button"
        id={styles["mobile-nav-toggle"]}
      >
        <i className={`fa ${isOpen ? "fa-times" : "fa-bars"}`}></i>
      </button>
      <a
        id={styles.lang}
        href={`/${firstPath ? (firstPath === "lt" ? "en" : "lt") : "lt"}/`}
        className="btn-link btn text-white"
      >
        {firstPath ? (firstPath === "lt" ? "LT" : "EN") : "LT"}
      </a>
      {isOpen && (
        <nav
          id={styles["mobile-nav"]}
          className={`animate__animated animate__faster ${
            isOpen ? "animate__fadeInLeft" : "animate__fadeOutLeft"
          } `}
        >
          <ul className={styles["nav-menu"]}>
            {sections.map(({ link, title }) => (
              <li key={link}>
                <Link
                  onClick={() => {
                    setIsOpen(!isOpen);
                    toggleBodyClass();
                  }}
                  to={link}
                >
                  {textResources.sections[title]}
                </Link>
              </li>
            ))}
            <li>
              <Link
                onClick={() => {
                  setIsOpen(!isOpen);
                  toggleBodyClass();
                }}
                to="/terms"
              >
                {texts.mobileLink1}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setIsOpen(!isOpen);
                  toggleBodyClass();
                }}
                to="/privacy"
              >
                {texts.mobileLink2}
              </Link>
            </li>
          </ul>
        </nav>
      )}
      <div
        id={styles["mobile-body-overly"]}
        style={{ display: isOpen ? "block" : "none" }}
        onClick={() => {
          setIsOpen(!isOpen);
          toggleBodyClass();
        }}
      ></div>
    </div>
  );
};

export default MobileMenuUtil;
