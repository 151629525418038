import React, { useState, useRef } from "react";

const PopoverComponent = ({ dataContent, children, className, marginTop }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [height, setHeight] = useState(0);

  const popoverRef = useRef();

  const onShowContent = () => {
    setHeight(popoverRef.current.clientHeight);
    setIsVisible(true);
  };

  const onHideContent = () => {
    setIsVisible(false);
  };

  return (
    <div
      onMouseEnter={onShowContent}
      onMouseLeave={onHideContent}
      className={`${className} my-auto px-4`}
    >
      <div
        className={`popover fade ${isVisible ? "show" : ""} bs-popover-top`}
        style={{
          marginTop: -height - (marginTop || 0)
        }}
      >
        <div className="arrow" />
        <div className="popover-body" ref={popoverRef}>
          {dataContent}
        </div>
      </div>
      {children}
    </div>
  );
};

export default PopoverComponent;
