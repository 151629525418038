import React, { useContext } from "react";
import Lukas from "../../../img/team/Lukas.jpg";
import Paulius from "../../../img/team/Paulius.jpg";
import styles from "./TeamSection.module.css";
import { Slide } from "react-awesome-reveal";
import { TextResourceContext } from "../../../contexts/TextResourceContext";

const TeamSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.TeamSection;
  const teamMembers = [
    {
      name: "Lukas Holsanskis",
      position: texts.teamCeoTitle,
      description: texts.teamCeoContent,
      picture: Lukas,
      linkToEmail: "mailto:lukas@confistech.com",
      linkedin: "https://www.linkedin.com/in/lukas-hol%C5%A1anskis-691246138",
      email: "lukas@confistech.com",
    },
    {
      name: "Paulius Savickas",
      position: texts.teamCtoTitle,
      description: texts.teamCtoContent,
      picture: Paulius,
      linkToEmail: "mailto:paulius@confistech.com",
      linkedin: "https://www.linkedin.com/in/paulius-savickas-19768914b/",
      email: "paulius@confistech.com",
    },
  ];

  return (
    <section id="team" className={`${styles["section-bg"]} ${styles["team"]}`}>
      <div className="container">
        <div className={styles["section-header"]}>
          <h3 className={styles["section-title"]}>{texts.teamTitle}</h3>
          <span className={styles["section-divider"]}></span>
          <Slide direction="up" triggerOnce>
            <p className={styles["section-description"]}>{texts.teamContent}</p>
          </Slide>
        </div>
        <div className="row">
          {teamMembers.map(
            ({
              name,
              position,
              description,
              picture,
              email,
              linkToEmail,
              linkedin,
            }) => (
              <div key={name} className="col-md-6 col-sm-12 pt-5">
                <Slide direction="up" triggerOnce>
                  <div className={styles.member}>
                    <div className={`${styles.pic}`}>
                      <img src={picture} alt={`${name} profile pic`} />
                    </div>
                    <h4>{name}</h4>
                    <span className="font-italic">{position}</span>
                    <span className={styles.memberDescription}>
                      {description}
                    </span>
                    <div className={styles.social}>
                      {linkToEmail && (
                        <a href={linkToEmail} target="_blank" rel="noreferrer">
                          <i className="fa fa-envelope"></i>
                        </a>
                      )}
                      <a href={linkedin} target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                    {email && (
                      <div>
                        <span className="font-italic">{email}</span>
                      </div>
                    )}
                  </div>
                </Slide>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
