import { TextResourceContext } from "../../../contexts/TextResourceContext";
import React, { useContext } from "react";
import styles from "./TechStack.module.css";
import Marquee from "react-fast-marquee";
import csharp from "../../../img/tech-stack/csharp.svg";
import docker from "../../../img/tech-stack/docker.svg";
import js from "../../../img/tech-stack/js.svg";
import mongodb from "../../../img/tech-stack/mongodb.svg";
import react from "../../../img/tech-stack/react.svg";
import ts from "../../../img/tech-stack/typescript.svg";
import postgres from "../../../img/tech-stack/postgres.svg";
import angular from "../../../img/tech-stack/angular.png";
import aws from "../../../img/tech-stack/aws.png";
import blazor from "../../../img/tech-stack/blazor.png";

const TechStackSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.TechStackSection;
  const techStack = [
    {
      img: csharp,
      text: ".NET(C#)",
    },
    {
      img: docker,
      text: "Docker",
    },
    {
      img: js,
      text: "JavaScript",
    },
    {
      img: mongodb,
      text: "MongoDB",
    },
    {
      img: react,
      text: "ReactJS",
    },
    {
      img: ts,
      text: "TypeScript",
    },
    {
      img: postgres,
      text: "PostgreSQL",
    },
    {
      img: angular,
      text: "Angular",
    },
    {
      img: aws,
      text: "Amazon Web Services",
    },
    {
      img: blazor,
      text: "Blazor",
    },
  ];
  return (
    <section id="technology" className={styles.technology}>
      <div className={styles["call-to-action"]}>
        <div className="container">
          <div className={`${styles["section-header"]} pb-sm-3 pb-lg-5`}>
            <h3 className={styles["section-title"]}>{texts.techStackTitle}</h3>
            <h4 className={styles["section-description"]}>
              {texts.techStackContent}
            </h4>
          </div>
        </div>
      </div>
      <Marquee
        play={true}
        className={styles.parentContainer}
        pauseOnHover={true}
      >
        {techStack.map((item) => (
          <div key={item.text} className={styles.childContainer}>
            <div className={styles.imgContainer}>
              <img className={styles.imgSize} alt="img" src={item.img} />
            </div>
            <div className={styles.nameContainer}>
              <h4 className={styles.marginNone}>{item.text}</h4>
            </div>
          </div>
        ))}
      </Marquee>
    </section>
  );
};

export default TechStackSection;
