import React, { useEffect, useState } from "react";
import styles from "./BackArrowComponent.module.css";
import { Link } from "react-router-dom";

const BackArrowComponent = () => {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const arrowAppearing = () => {
      const isTopPosition = window.scrollY < 100;
      setIsTop(isTopPosition);
    };

    document.addEventListener("scroll", arrowAppearing);

    return () => {
      document.removeEventListener("scroll", arrowAppearing);
    };
  }, []);

  return (
    <div>
      <Link
        to="#"
        className={`${styles["back-to-top"]} animate__animated  ${
          isTop ? "animate__fadeOut" : "animate__fadeIn"
        }`}
      >
        <i className="fa fa-chevron-up"></i>
      </Link>
    </div>
  );
};

export default BackArrowComponent;
