import React, { useContext } from "react";
import styles from "./IntroSection.module.css";
import { TextResourceContext } from "../../../contexts/TextResourceContext";

const IntroSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.IntroSection;
  
  return (
    <section id="intro" className={styles.intro}>
      <div className={styles["intro-text"]}>
        <h2>
          <div dangerouslySetInnerHTML={{__html: texts.introHeader}} />
        </h2>
        <h3>
          <div className={styles["intro-subHeader"]}>{texts.introSubHeader}</div>
        </h3>
        <a href="#contact" className={`cc-btn ${styles["btn-get-started"]}`}>
          {texts.introButton}
        </a>
      </div>
    </section>
  );
};

export default IntroSection;
