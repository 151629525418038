import React from "react";
import Home from "./pages/homepage/HomePage";
import Terms from "./pages/termspage/TermsPage";
import Privacy from "./pages/privacypage/PrivacyPage";
import NotFoundPage from "./pages/notfoundpage/NotFoundPage";
import { Route, useLocation, Switch } from "react-router-dom";
import Header from "./utils/header/HeaderUtil";
import Footer from "./utils/footer/FooterUtil";
import MobileMenu from "./utils/mobilemenu/MobileMenuUtil";
import BackArrow from "./components/backarrow/BackArrowComponent";
import Cookie from "./components/cookie/CookieComponent";

const Navigation = () => {
  const headerHeight = 72;
  const { hash, pathname } = useLocation();

  const entries = performance.getEntriesByType("navigation");
  if (!entries.some((entry) => entry === "back_forward")) {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          window.scrollTo(0, element.offsetTop - headerHeight);
        }
      }, 300);
    }
  }

  return (
    <>
      <Cookie />
      <MobileMenu />
      <Header
        isAnimationDisabled={
          !(pathname === "/lt/" || pathname === "/en/" || pathname === "/")
        }
      />
      <Switch>
        <Route
          path={["/en/privacy", "/lt/privacy", "/privacy"]}
          exact
          component={Privacy}
        />
        <Route
          path={["/lt/terms", "/en/terms", "/terms"]}
          exact
          component={Terms}
        />
        <Route path={["/en/", "/lt/", "/"]} exact component={Home} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
      <BackArrow />
      <Footer />
    </>
  );
};

export default Navigation;
