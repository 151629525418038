import React, { useContext } from "react";
import styles from "./FooterUtil.module.css";
import { sections } from "../../components/sections";
import { Link } from "react-router-dom";
import { TextResourceContext } from "../../contexts/TextResourceContext";

const FooterUtil = () => {
  const { textResources, isLoading } = useContext(TextResourceContext);
  const texts = textResources.FooterUtil;
  return (
    !isLoading && (
      <footer id={styles.footer}>
        <div className="mx-5">
          <div className="row">
            <div className="col-xl-2 text-xl-left text-center">
              <div className="copyright">
                &copy; Copyright <strong>ConfiStecH</strong>
              </div>
            </div>
            <div className="col-xl-10">
              <nav
                className={`${styles["footer-links"]} text-xl-right text-center pt-2 pt-lg-0 d-flex flex-wrap justify-content-center`}
              >
                {sections.map(({ link, title }) => (
                  <Link key={link} to={link}>
                    {textResources.sections[title]}
                  </Link>
                ))}
                <Link to="./terms">{texts.footerLink1}</Link>
                <Link to="./privacy">{texts.footerLink2}</Link>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    )
  );
};

export default FooterUtil;
