import React, { useContext, useLayoutEffect, useState } from "react";
import { TextResourceContext } from "../../../contexts/TextResourceContext";
import Carousel from "react-bootstrap/Carousel";
import styles from "./PortfolioSection.module.css";
import portfolio1 from "../../../img/portfolio/portfolio1.png";
import portfolio2 from "../../../img/portfolio/portfolio2.png";
import portfolio3 from "../../../img/portfolio/portfolio3.png";
import portfolio4 from "../../../img/portfolio/portfolio4.png";
import playstore from "../../../img/portfolio/playstore.png";

const PortfolioSection = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.PortfolioSection;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useLayoutEffect(() => {
    const sectionResize = () => {
      const ismobile = window.innerWidth < 992;
      setIsMobile(ismobile);
    };
    window.addEventListener("resize", sectionResize);
    return () => {
      window.removeEventListener("resize", sectionResize);
    };
  }, []);

  const portfolio = [
    {
      title: "AnyMoment",
      header: texts.portfolioHeader3,
      linkToPlayStore: playstore,
      challenge: texts.portfolioChallenge3,
      solution: texts.portfolioSolution3,
      tech: texts.portfolioTech3,
      img: portfolio3,
    },
    {
      header: texts.portfolioHeader1,
      challenge: texts.portfolioChallenge1,
      solution: texts.portfolioSolution1,
      tech: texts.portfolioTech1,
      img: portfolio1,
    },
    {
      header: texts.portfolioHeader2,
      challenge: texts.portfolioChallenge2,
      solution: texts.portfolioSolution2,
      tech: texts.portfolioTech2,
      img: portfolio2,
    },
    {
      header: texts.portfolioHeader4,
      contributions: [
        texts.portfolioContributionItem1,
        texts.portfolioContributionItem2,
        texts.portfolioContributionItem3,
        texts.portfolioContributionItem4,
        texts.portfolioContributionItem5,
      ],
      img: portfolio4,
    },
  ];

  return (
    <section id="portfolio" className={styles.portfolio}>
      <div className="section-header pb-sm-3 pb-lg-4">
        <h3 className="section-title">{texts.portfolioTitle}</h3>
        <span className="section-divider"></span>
      </div>
      <Carousel
        indicators={isMobile ? true : false}
        controls={isMobile ? false : true}
        interval={null}
        className={`${styles.carousel}`}
      >
        {portfolio.map(
          ({
            title,
            header,
            challenge,
            solution,
            tech,
            img,
            contributions,
            linkToPlayStore,
          }) => (
            <Carousel.Item key={header} className={`${styles.carousel_item}`}>
              <div className="container">
                <Carousel.Caption className={`row ${styles.carousel_caption}`}>
                  <div className={`col-lg mb-4 ${styles.img_wrapper}`}>
                    <img src={img} alt="portfolio" />
                    {linkToPlayStore ? (
                      <div className={styles.playStore}>
                        <a
                          href={
                            "https://play.google.com/store/apps/details?id=confistech.mvp"
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={linkToPlayStore} alt="playstore" />
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>{" "}
                  <div className={`col-sm ${styles.carousel_text}`}>
                    <h3>
                      {title ? (
                        <a
                          href={"https://anymoment.app/"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>{title} </span>
                        </a>
                      ) : (
                        ""
                      )}
                      {header}
                    </h3>

                    {challenge && (
                      <>
                        <p>
                          <span>{texts.portfolioChallenge}:</span> {challenge}
                        </p>
                        <p>
                          <span>{texts.portfolioSolution}:</span> {solution}
                        </p>
                        <p>
                          <span>{texts.portfolioTech}:</span> {tech}
                        </p>
                      </>
                    )}

                    {contributions && (
                      <ul>
                        {contributions.map((contribution, index) => (
                          <li key={index}> {contribution}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          )
        )}
      </Carousel>
    </section>
  );
};

export default PortfolioSection;
