import React, { useContext } from "react";

import ES_logo from "../../img/partners/ES_logo.png";
import Y_Combinator from "../../img/partners/Y_Combinator_logo.png";
import Google from "../../img/partners/Google_Cloud_for_Statups_logo.png";
import KaunasSTP from "../../img/partners/kaunas-stp-logo.png";
import KaunasPPAR from "../../img/partners/kppar_logo.png";
import LightHouseHub from "../../img/partners/lighthousehub_logo.png";
import Pixinn from "../../img/partners/pixinn_logo.png";
import TechHub from "../../img/partners/vu_techhub_logo.png";

import Popover from "../../components/popover/PopoverComponent";

import { TextResourceContext } from "../../contexts/TextResourceContext";

const PartnersListUtil = () => {
  const { textResources } = useContext(TextResourceContext);
  const texts = textResources.PartnersListUtil;

  const imageAttributes = [
    {
      id: 1,
      src: ES_logo,
      alt: "ES",
      dataContent: texts.partnersContent1,
    },
    {
      id: 2,
      src: Y_Combinator,
      alt: "Y Combinator",
      dataContent: (
        <>
          {texts.partnersContent2}
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.startupschool.org/companies/confistech"
          >
            https://www.startupschool.org/companies/confistech
          </a>
        </>
      ),
    },
    {
      id: 3,
      src: Google,
      alt: "Google Cloud for Startups",
      dataContent: texts.partnersContent3,
    },
    {
      id: 4,
      src: KaunasSTP,
      alt: "Kaunas Science and Technology Park",
      dataContent: texts.partnersContent4,
    },
    {
      id: 5,
      src: TechHub,
      alt: "TechHub",
      dataContent: texts.partnersContent5,
    },
    {
      id: 6,
      src: LightHouseHub,
      alt: "LightHouse Hub",
      dataContent: texts.partnersContent6,
    },
    {
      id: 7,
      src: Pixinn,
      alt: "Pixinn",
      dataContent: texts.partnersContent7,
    },
    {
      id: 8,
      src: KaunasPPAR,
      alt: "Kaunas Chamber of Commerce, Industry and Crafts",
      dataContent: texts.partnersContent9,
    },
  ];
  return (
    <div
      id="partnerListMedia"
      className="row align-items-center justify-content-center"
    >
      {imageAttributes.map(({ id, src, alt, dataContent }) => {
        return (
          <Popover
            className="col-md-4 col-lg-3 d-flex justify-content-center align-items-center gap-sm-5"
            id="popover"
            key={id}
            dataContent={dataContent}
          >
            <img src={src} alt={alt} />
          </Popover>
        );
      })}
    </div>
  );
};

export default PartnersListUtil;
