export const sections = [
  {
    link: "./#talents",
    title: "sectionsTitle1"
  },
  {
    link: "./#portfolio",
    title: "sectionsTitle2"
  },
  {
    link: "./#technology",
    title: "sectionsTitle3"
  },
  {
    link: "./#team",
    title: "sectionsTitle4"
  },
  {
    link: "./#careers",
    title: "sectionsTitle5"
  },
  {
    link: "./#contact",
    title: "sectionsTitle6"
  },
];
