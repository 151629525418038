import React, { useReducer, createContext } from "react";
import AppReducer from "./AppReducer";
import { getTextResource } from "../clients/textResourceClient";
import * as actions from "./actions";

const initialState = {
  textResources: [],
  isLoading: true,
  currentLang: null
};

export const TextResourceContext = createContext(initialState);

export const TextResourceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setLanguage = (language) => {
    dispatch({
      type: actions.SET_LANGUAGE,
      payload: language
    });
    return getTextResources(language);
  };

  const getTextResources = (language) => {
    if (state.currentLang === language) {
      Promise.resolve();
    }
    return getTextResource(language).then((resources) => {
      dispatch({
        type: actions.SET_TXT_RESOURCES,
        payload: resources
      });
    });
  };

  const setLoader = (boolean) => {
    dispatch({
      type: actions.SET_LOADER,
      payload: boolean
    });
  };

  return (
    <TextResourceContext.Provider
      value={{
        textResources: state.textResources,
        isLoading: state.isLoading,
        setLanguage,
        setLoader
      }}
    >
      {children}
    </TextResourceContext.Provider>
  );
};
